@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}



.no-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer; /* Optional: adds a cursor indication that this is hoverable */
  font-weight: normal;
    font-size: 20px;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  background-color: black;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  white-space: normal; /* Allows text to wrap */
  
  /* Dynamic width adjustments */
  min-width: 120px; /* Minimum width of the tooltip */
  max-width: 300px; /* Maximum width to prevent it from getting too wide */
  width: auto; /* Allows the width to grow with content, up to max-width */
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Adjust based on your needs */
  left: 50%;
  transform: translateX(-50%); /* Dynamically centers the tooltip */
  
  /* Transition for smoother visibility change */
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.img-logo-wrapper {
  flex: 0 1 8%;
}

.navbar {
  justify-content: space-between;
  padding: 10px 75px;
}

.text-logo {
  font-size: 2.5em;
  font-weight: bold;
  --tw-bg-opacity: 1;
  color: rgb(65 84 125);
  opacity: 0; /* Initially set to transparent */
  transition: opacity 3s ease-in-out; /* Fade-in transition */
}

.text-logo.fade-in {
  opacity: 1; /* Set to fully opaque */
}

.menu-buttons-wrapper {
  display: flex;
  justify-content: space-evenly;
  flex: 0 0 33%;
}

.menu-buttons {
  display: flex;
  justify-content: space-around;
}

.menu-link{
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}

.menu-link a {
  color:#264d5a;
  font-weight: bold;
  font-size: 1.3em;
}

.loading-data {
  display: none;
}

.bg-gray-900.main-intro-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  border-radius: 10px;
}

.main-intro, .main-3-cols {
  max-width: 1100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 60px 0px;
}


.button-wrapper {
  flex: 1 0 100%;
}

button.btn.btn-lg.text-neutral
{
  cursor: pointer;
  background-color: #68aac0;
  border-color: #68aac0;
  letter-spacing: 2px;
}

button.btn.btn-lg.text-neutral:hover
{
  cursor: pointer;
  background-color: #264d5a;
  border-color: #264d5a;
  color: #dbdbdb;
}

@media only screen and (max-width:1200px) {
  .main-intro, .main-3-cols {
    max-width: 900px;
  }
}

.intro-and-3cols-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.main-3-cols {
  align-items: flex-start;
}

.main-col {
  padding:0px 15px;
  text-align: left;
}

.main-col div {
  font-size: 1.2em;
}
.main-col h2, .main-col h3, .main-col h4  {
  color:#264d5a;
  font-weight: bold;
}

.main-col1,.main-col2 {
  flex: 1 0 50%;
}

.main-col h2 {
  padding-bottom: 25px;
}
.main-col p {
  padding: 10px 0px;
}

.main-col1-2nd-part {
  padding-top: 100px;
}

p.main-col2-underheading {
  padding-bottom: 40px;
}

.main-col3 {
  flex: 1 0 49%;
}

.cols-last-sentance {
  padding-top:50px;
}

.input-carousel {
  padding: 120px 0px;
}

.carousel button.btn {
  margin-right: 10px;
}


.email-form-container {
  max-width: 400px;
  margin: 50px auto;
  font-size: 1.2em;
}

.email-form {
  display: flex;
  flex-direction: column;
}

.email-input {
  width: 100%;
  margin-bottom: 15px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  transition: border-color 0.3s ease;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.submit-button {
  width: 100%;
  padding: 10px;
  background-color: #68aac0 !important;
  border-color: #68aac0 !important;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
}

.submit-button:hover {
  background-color: #264d5a !important;
  border-color: #264d5a !important;
  color: #dbdbdb;
}

button#btn-up, button#btn-down {
  background-color: #68aac0 !important;
  border-color: #68aac0 !important;
}

button#btn-up:hover, button#btn-down:hover {
  background-color: #264d5a !important;
  border-color: #264d5a !important;
}

.loading-container {
  width: 100%; /* Full width of the container */
  padding: 20px; /* Some padding */
  text-align: center; /* Center the content */
  background: #f3f3f3; /* Light background */
}

.input-wrapper {
  background-color:#c4dbe4;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 1.1em;
}

.input-wrapper div.flex {
  flex:1 0 100%;
}

.input-cols {
  gap:50px;
  
}

.input-cols-1, .input-cols-2 {
  flex: 0 0 45%;
  height:700px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-around;
}

.input-btn-wrapper {
  flex: 1 0 100%;
  flex-wrap: wrap;
}

.input-btn {
  width: 15%;
    font-size: 1.2em;
    font-weight: 500;
    color: white;
    background-color: #264d5a;
    border-color: #264d5a;
    padding: 10px;
    text-transform: uppercase;
    border-radius: 10px;
}

.input-btn:hover {
  color:#262121;
  background-color:#e5e7eb;
  border-color:#e5e7eb;
}

.input-header-wrapper {
  margin: 50px auto;
  overflow: hidden; 
}

.input-header-txt {
  font-size: 2.4em;
  font-weight: bold;
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 2s ease, transform 2s ease;
}

.slide-in {
  opacity: 1;
  transform: translateX(0);
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.loading-text {
  font-size: 1.2em;
}

.loading-container {
  width: 50%;
  padding: 20px;
  text-align: center;
  color: white;
  background-color: #264d5a;
  border-color: #264d5a;
  border-radius: 10px;
}

.text-error-message {
  width: 100%;
  padding-bottom: 10px;
  color: #950000;
  font-size: 1.2em;
}

.input-cols {
  max-width: 1100px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}

.loading-container-wrapper {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    top: -76px;
}

.results-wrapper {
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}


.results-container,.email-container {
  max-width: 1100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

}

.results-2cols {
  display: flex;
  justify-content: center;
  gap:30px;
  flex: 0 0 80%;
  flex-wrap: wrap;
}


.input-cols-1 .mb-4, .input-cols-2 .mb-4  {
  flex: 1 0 100%;
}

.std-1stcol, .std-2ndcol, .std-2cols-wrapper div{
  text-align: left;
  flex: 1 0 47%;
}


.results h1 {
  font-size: 1.8rem;
  margin-bottom: 50px;
  background-color: white;
  width: 45%;
  text-align: center;
  color: #264d5a;
  padding: 20px 0;
  border-radius: 10px;
  font-weight: bold;
}

.results,  .std-wrapper , .email-wrapper{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.std-1stcol p, .std-2ndcol  p{
  font-size: 1.2em;
}

.results-1st-col p.font-bold,.results-2nd-col p.font-bold {
  font-size: 1.3em;
}
.results-2nd-col p.regular-text,
.mortgage-2cols-wrapper p.regular-text,
.lifetime-2ndcol p.regular-text,
.staircasing-explained .results-1st-col p.regular-text  {
  font-size: inherit;
}

.results-2nd-col .results-sharedOwn .toggle-icon , .results-2nd-col .results-number .toggle-icon ,
.staircasing-chartcol .results-sharedOwn .toggle-text-container .toggle-icon,
.lifetime-2ndcol .results-sharedOwn .toggle-text-container  .toggle-icon,
.mortgage-2cols-wrapper .results-sharedOwn .toggle-text-container  .toggle-icon,
.staircasing-explained .results-sharedOwn .toggle-text-container  .toggle-icon {
  font-size: 0.5em;
}

.staircasing-explained  .results-2cols .mb-3 .toggle-text-container  .toggle-icon {
  font-size: 0.8em;
}

.staircasing-explained .results-2cols {
  flex:inherit;
}

.results-fullOwn, .results-sharedOwn {
  font-size: 2rem;
  margin-bottom: 35px;
  padding: 3px;
  border-bottom: 1px solid;
  font-weight: 700;
}

.staircasing-chartcol {
  margin-top: 30px;
}

.staircasing-chartcol .results-sharedOwn .toggle-text-container {
  justify-content: center;
}


 .std-wrapper h1{
  font-size: 2.8rem;
  margin-bottom: 50px;
}

 .std-2cols-wrapper {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex: 0 0 80%;
  flex-wrap: wrap;
}

 .std-wrapper {
  margin: 120px 0px 120px;

}

.grapics-note {
  margin: 80px 0px 10px 0px;
}

.tooltiptext {
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;
  position: absolute;
  z-index: 1;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  width: 150px; /* Adjust width as needed */
}

.tooltip.show .tooltiptext {
  display: block;
  visibility: visible;
  opacity: 1;
}

.charts-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.charts {
  flex: 1 0 100%;
  max-width: 900px;
  margin-top: 80px;
}

.charts-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex: 1 0 100%;
  width: 100%;
}

.faq-img {
  margin: 4rem 0px;
}

.blog-wrapper, .tc-wrapper{
  max-width: 1100px;
  margin: 3rem 0px;
}


#faq {
  width: 80%;
}

.more-secion-wrapper {
  background-color: #c4dbe4;
  color: black;
}

.contact-form-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-form-container {
  background: white;
  padding: 20px;
  border-radius: 8px;
}

.contact-form-close {
  position: relative;
  top: -19px;
  right: -131px;
  cursor: pointer;
  font-size: 1.3em;
  font-weight: bold;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.banner.banner-text {
  width: 100%;
  display: inline-block;
  text-align: center;
  max-width: 1100px;
  background-color: #c4dbe4;
  border-radius: 10px;
  padding: 15px;
}

.banner-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
  font-size: 1.1em;
}

.banner-buttons-wrapper {
  padding-top: 10px;
  display: flex;
  justify-content: center;
  gap: 30px;
}

.banner-buttons-wrapper button,.contact-form button{
    font-weight: 500;
    color: white;
    background-color: #264d5a;
    border-color: #264d5a;
    padding: 10px;
    border-radius: 10px;
}

.banner-buttons-wrapper button:hover, .contact-form button:hover {
  color: #262121;
  background-color: #e5e7eb;
  border-color: #e5e7eb;
}

.contact-form input,.contact-form textarea {
  width: 100%;
  margin-bottom: 15px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  transition: border-color 0.3s ease;
}

.contact-form label {
  text-align: left;
}

.contact-form {
  font-size: 1.1em;
}

.contact-form button:hover {
  background-color: #8ba4ad;
  border-color: #8ba4ad;
}

.results-number {
  font-size: 1.7em;
  color: #3b82f6;
  margin-bottom: 45px;
  font-weight: bold;
}

#faqs {
  margin:100px auto
}

#faqs div.rounded-md {
  background-color: #8ba4ad85;
}

.social-icon {
  width: 24px;
  height: 24px;
  vertical-align: middle; 
  transition: opacity 0.3s ease;
}

.social-icon:hover {
  opacity: 0.7;
}

.flex {
  display: flex;
  flex-direction: row;  
  align-items: center;  
  justify-content: center;
}

.social-icon {
  vertical-align: bottom; 
  width: 24px;  
  height: 24px; 
  margin-top: -2px; 
  transition: opacity 0.3s ease;
}

.social-icon:hover {
  opacity: 0.7;  
}

.toggle-text-container {
  position: relative;
  display: flex;
}

.regular-text {
  display: inline;
  margin-right: 5px; /* Adjust spacing between regular text and icon */
}

.toggle-icon {
  cursor: pointer;
}

.toggleable-text {
  position: absolute;
  background-color: #000;
  color: #fff;
  padding: 10px;
  border-radius: 6px;
  width: 400px;
  z-index: 1;
  white-space: normal; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  top: calc(100% + 5px); 
  left: 0;
  display: none; 
  font-size: 12px;
}

.toggleable-text p {
  margin: 0; /* Remove default margin */
}

.toggle-text-container:hover .toggleable-text {
  display: block; /* Show on hover */
}

p.undergraph-text.text-white {
  padding-top: 25px;
  font-size: 16px;
  text-align: center;
}

.faq-globalwrapper {
  background-color: #c4dbe4;
  color: black;
  padding: 40px 0px;
  border-radius: 10px;
}

h1.faq-header {
  margin: 100px;
  text-align: center;
  font-size: 2.5em;
  font-weight: bold;
}
.email-wrapper div {
  flex: 1 0 100%;
}

.prefooter-wrapper {
  max-width: 1100px;
  text-align: center;
  display: inline-block;
  margin: 50px auto;
}

.tnc-globalwrapper {
  background-color: #c4dbe4;
  color: black;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  padding: 100px 150px;
}

.lifetime-wrapper h1 svg {
  font-size: 0.5em;
}

input.email-input {
  color: black;
}

@media screen and (max-width:1100px) {
  .std-wrapper {
    padding:0px 20px
  }
  .std-diagram {
    max-width: calc(100vw - 5%);
    width: 100%;
  }
  .grapics-note {
    font-size: 1.2em;
  }

  #btn-up, #btn-down {
    display: none;
  }

  .staircasing-explained .results-2cols {
    flex: 0 0 80%;
  }
  
}
/* 
@media screen and (max-width:800px) {

 .std-diagram{
    max-width: calc(100vw - 5%);
    width: 100%;
  }

  
}
*/ 


@media screen and (max-width:431px) {

  .std-diagram{
    max-width: calc(100vw - 5%);
    width: 100%;
  }
  .navbar {
    flex-wrap: wrap;
    padding: 25px 10px;
  }
  .navbar .img-logo-wrapper, .navbar .text-logo {
    flex: 1 0 30%;
    padding: 20px;
  }

  .navbar .menu-buttons-wrapper {
    flex: 1 0 100%;
  }
  .text-logo {
    font-size: 2em;
  }

  p.text-3xl.text-white.py-10 {
    font-size: 1.5rem;
  }

  h1.text-6xl.text-white.py-10 {
    font-size: 2.5rem;
  }

  .main-intro-wrapper, .main-3-cols, .input-wrapper, .before-footer-wrapper .results-wrapper, .before-footer-wrapper .prefooter-wrapper  {
    padding-right:25px;
    padding-left:25px;
  }
  .main-col1, .main-col2 {
     flex: 1 0 100%; 
  }
  .main-col1-2nd-part {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .main-col {
    padding: 0;
  }
  .input-header-txt {
    font-size: 2em;
  }
  .input-cols {
     gap: 0px; 
  }
  .input-cols-1, .input-cols-2 {
    flex: 1 0 100%;
    height: inherit;
  }
  .input-cols-1 div, .input-cols-2 div {
    margin-bottom: 40px;
  }
  .input-btn {
    width: inherit;
  }

  div#results {
    padding: 0px;
  }
  .std-wrapper h1 {
    font-size: 2.2rem;
  }
  .std-wrapper, .results {
    margin: 60px 0px 60px;
  }
  .std-1stcol, .std-2ndcol, .std-2cols-wrapper div {

    flex: 1 0 100%;
  } 

  .toggleable-text {
    width: calc(100vw - 100px);
  }

  .lifetime-wrapper .toggleable-text {
    left: -287px;
  }
  
  .results-fullOwn, .results-sharedOwn {
    font-size: 1.7rem;
    padding: inherit;
  }
  .results-number {
    font-size: 1.4em;
    margin-bottom: 25px;

  }

  .results h1 {
    font-size: 1.5rem;
    margin-bottom: 30px;
    width: 60%;
  }

  h1.faq-header {
    margin: 10px auto;

    font-size: 2.2em;
  }
  .tnc-globalwrapper{
    padding:25px;
  }

  .std-1stcol, .std-2ndcol, .lifetime-wrapper div#comp {
    margin-bottom:55px;
  }
}